import { Button, DropDownBox, Popup, SelectBox, TextArea, TextBox } from "devextreme-react";
import { Column, Editing, Paging, Texts, Pager, RequiredRule, SearchPanel } from "devextreme-react/data-grid";
import { ButtonItem, Form, GroupItem, Label, SimpleItem } from "devextreme-react/form";
import React from "react";
import {
    addShopToLicense,
    changeImageVersion,
    deploy,
    getAvailableVersions,
    getDeployStatus,
    getLicenseById,
    reactivateLicense,
    revokeLicense,
    sendEnvironmentVariables,
    startInstance,
    stopInstance,
    updateLicense,
} from "../../../actions/licensesActions";
import { getAllModules } from "../../../actions/moduleActions";
import store from "../../../store";
import { updateShop, deployShop } from "../../../actions/shopActions";
import { Height } from "devextreme-react/chart";

class License extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseId: this.props.licenseId,
            shops: [],
            license: undefined,
            deployStatus: 0,
            deployStatusString: "",
            modules: [],
            modulesBought: [],
            modulesBoughtNames: [],
            availableVersions: [],
            selectedVersion: undefined,
            showRevokeLicensePopup: false,
            revokedReason: "",
        };

        this.onLicenseChanged = this.onLicenseChanged.bind(this);
        this.deployButtons = this.deployButtons.bind(this);
        this.deploy = this.deploy.bind(this);
        this.getDeployStatus = this.getDeployStatus.bind(this);
        this.onStoreNameChanged = this.onStoreNameChanged.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.removeByAttr = this.removeByAttr.bind(this);
        this.getAvailableVersions = this.getAvailableVersions.bind(this);
        this.onVersionChanged = this.onVersionChanged.bind(this);
        this.handleInsert = this.handleInsert.bind(this);
        this.toggleRevokeLicensePopup = this.toggleRevokeLicensePopup.bind(this);

        this.validationRules = {
            owner: [{ type: "required", message: "Inhaber muss angegeben werden!" }],
            storeName: [{ type: "required", message: "Der Name muss angegeben werden!" }],
            validityDate: [{ type: "required", message: "Das Ablaufdatum muss angegeben werden!" }],
        };
    }

    componentDidMount() {
        //store.dispatch(sendEnvironmentVariables(10134));
        this.getAvailableVersions();
        store.dispatch(getAllModules()).then((response) => this.setState({ modules: response }));
        if (this.state.licenseId) {
            store.dispatch(getLicenseById(this.state.licenseId)).then((result) => {
                this.setState({
                    license: result,
                    deployStatus: result.Container ? result.deployStatus : 0,
                    deployStatusString: result.Container ? result.deployStatusString : "",
                    selectedVersion: result.Container
                        ? result.containerImage.replace("foodgenius.azurecr.io/bios/bios:", "")
                        : "latest",
                });
            });
        }
    }

    removeByAttr(arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (arr[i] && arr[i].hasOwnProperty(attr) && arguments.length > 2 && arr[i][attr] === value) {
                arr.splice(i, 1);
            }
        }
        return arr;
    }

    getAvailableVersions() {
        store.dispatch(getAvailableVersions()).then((result) =>
            this.setState({
                availableVersions: result.map((version) => {
                    return version.replace("/", "");
                }),
            })
        );
    }

    deploy() {
        this.setState({
            deployStatusString: "(1/4) Deploy gestartet",
            deployStatus: 1,
        });
        store.dispatch(
            deploy(this.state.licenseId, this.state.selectedVersion ? this.state.selectedVersion : "latest")
        );
    }

    render() {
        return (
            <div>
                {this.state.license && (
                    <Form formData={this.state.license} disabled={this.state.loading}>
                        <GroupItem cssClass="first-group" colCount={4}>
                            <GroupItem colSpan={3}>
                                <SimpleItem
                                    dataField="StoreName"
                                    editorOptions={{ onValueChanged: this.onStoreNameChanged }}
                                    validationRules={this.validationRules.storeName}
                                >
                                    <Label text={"Name"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Owner"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    validationRules={this.validationRules.owner}
                                >
                                    <Label text={"Inhaber / GF"} />
                                </SimpleItem>
                                <SimpleItem dataField="LicenseNumber" disabled>
                                    <Label text={"Lizenznummer"} />
                                </SimpleItem>
                                <SimpleItem dataField="companyKey" disabled>
                                    <Label text={"Unternehmensschlüssel"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="ValidityDate"
                                    editorType="dxDateBox"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged, defaultValue: new Date() }}
                                    validationRules={this.validationRules.validityDate}
                                >
                                    <Label text={"Lizenzablauf"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem render={this.deployButtons} />
                            </GroupItem>
                        </GroupItem>
                        <GroupItem cssClass="second-group" colCount={2}>
                            <GroupItem colSpan={1}>
                                <SimpleItem dataField="title" editorOptions={{ onValueChanged: this.onLicenseChanged }}>
                                    <Label text={"Titel"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="ownerLastName"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Nachname Inhaber"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="houseNumber"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Hausnummer"} />
                                </SimpleItem>
                                <SimpleItem dataField="Zip" editorOptions={{ onValueChanged: this.onLicenseChanged }}>
                                    <Label text={"Postleitzahl"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="ownerFirstName"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Inhaber Vorname"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Street"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Straße"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="AddressDetails"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Adresszusatz"} />
                                </SimpleItem>
                                <SimpleItem dataField="City" editorOptions={{ onValueChanged: this.onLicenseChanged }}>
                                    <Label text={"Stadt"} />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem cssClass="third-group" colCount={2}>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="deployFrontendUrl"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Deploy-Frontend-Url"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="shopFrontendUrl"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Shop-Frontend-Url"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="ratingEnabled"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Bewertungen aktiviert"} />
                                </SimpleItem>
                                <ButtonItem
                                    //Button to deploy shop
                                    text="Shop deployen"
                                    onClick={() => store.dispatch(deployShop(this.state.licenseId, "latest"))}
                                />
                            </GroupItem>
                        </GroupItem>
                        <GroupItem cssClass="fourth-group" colCount={2}>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="databaseServer"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Datenbank Server"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="databaseUser"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Datenbank Benutzer"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="containerImage"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Container Image"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="databaseName"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Datenbank Name"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="databasePassword"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Datenbank Passwort"} />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>

                        <GroupItem cssClass="fifth-group" colCount={1}>
                            <GroupItem colSpan={1}>
                                <SimpleItem dataField="dataProtectionPolicy">
                                    <Label
                                        text={"Datenschutz Vereinbarung"}
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="impressum">
                                    <Label
                                        text={"Impressum"}
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                    </Form>
                )}
                <div>
                    <Popup
                        visible={this.state.showRevokeLicensePopup}
                        onHiding={this.toggleRevokeLicensePopup}
                        title={"Lizenz sperren"}
                        height={200}
                        width={400}
                        style={{ width: 400, height: 200 }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <TextArea
                                placeholder={"Grund für die Sperrung"}
                                style={{ height: 50 }}
                                value={this.state.license ? this.state.license.RevokedReason : ""}
                                onValueChanged={(e) => this.setState({ revokedReason: e.value })}
                            />
                            <Button
                                text={
                                    this.state.license && !this.state.license.Revoked
                                        ? "Lizenz sperren"
                                        : "Lizenz entsperren"
                                }
                                type="danger"
                                style={{ marginTop: 10 }}
                                onClick={() => {
                                    if (this.state.license && !this.state.license.Revoked) {
                                        store.dispatch(revokeLicense(this.state.licenseId, this.state.revokedReason));
                                    } else {
                                        store.dispatch(reactivateLicense(this.state.licenseId));
                                    }
                                }}
                            />
                        </div>
                    </Popup>
                </div>
            </div>
        );
    }

    handleDeploy(data) {
        store.dispatch(deployShop(this.state.licenseId, data.shopId));
    }

    handleInsert(e) {
        store.dispatch(
            addShopToLicense(this.state.licenseId, e.data.shopName, e.data.shopKind, e.data.url, e.data.image)
        );
    }

    handleUpdate(e) {
        store.dispatch(updateShop(e.data));
    }

    onVersionChanged(e) {
        this.setState({ selectedVersion: e.value });
    }

    deployButtons() {
        if (this.state.deployStatus === 0) {
            return (
                <div style={{ display: "grid" }}>
                    <SelectBox
                        items={this.state.availableVersions}
                        selectedItem={this.state.selectedVersion}
                        onValueChanged={this.onVersionChanged}
                        value={
                            this.state.selectedVersion
                                ? this.state.selectedVersion
                                : this.state.license.containerImage
                                ? this.state.license.containerImage.replace("foodgenius.azurecr.io/bios/bios:", "")
                                : "release"
                        }
                    />
                    <Button
                        text={"Deploy"}
                        type="default"
                        style={{ marginTop: 10 }}
                        onClick={this.deploy}
                        disabled={this.state.license && this.state.license.deployFrontendUrl === ""}
                    />
                    <Button
                        text={"Version ändern"}
                        type="default"
                        style={{ marginTop: 10 }}
                        onClick={() =>
                            store.dispatch(changeImageVersion(this.state.licenseId, this.state.selectedVersion))
                        }
                    />
                    {this.state.license && this.state.license.Revoked === false && (
                        <Button
                            text={"Lizenz sperren"}
                            type="danger"
                            style={{ marginTop: 10 }}
                            onClick={() => this.toggleRevokeLicensePopup()}
                        />
                    )}
                    {this.state.license && this.state.license.Revoked && (
                        <Button
                            text={"Lizenz entsperren"}
                            type="danger"
                            style={{ marginTop: 10 }}
                            onClick={() => this.toggleRevokeLicensePopup()}
                        />
                    )}
                    <Button
                        text={"Instanz starten"}
                        type="default"
                        style={{ marginTop: 10 }}
                        onClick={() =>
                            store.dispatch(
                                startInstance(
                                    "bios",
                                    this.state.license ? this.state.license.deployFrontendUrl.replaceAll(".", "-") : ""
                                )
                            )
                        }
                    />
                    <Button
                        text={"Instanz stoppen"}
                        type="default"
                        style={{ marginTop: 10 }}
                        onClick={() =>
                            store.dispatch(
                                stopInstance(
                                    "bios",
                                    this.state.license ? this.state.license.deployFrontendUrl.replaceAll(".", "-") : ""
                                )
                            )
                        }
                    />
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>Deploy-Status: {this.state.deployStatusString}</div>
                        <Button
                            text={"Status aktualisieren"}
                            type="default"
                            onClick={this.getDeployStatus}
                            width={160}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 20,
                        }}
                    >
                        <SelectBox
                            items={this.state.availableVersions}
                            selectedItem={this.state.selectedVersion}
                            onValueChanged={this.onVersionChanged}
                        />
                        <Button
                            text={"Version ändern"}
                            type="default"
                            width={160}
                            onClick={() =>
                                store.dispatch(changeImageVersion(this.state.licenseId, this.state.selectedVersion))
                            }
                        />
                    </div>
                </div>
            );
        }
    }

    toggleRevokeLicensePopup() {
        this.setState({
            showRevokeLicensePopup: !this.state.showRevokeLicensePopup,
        });
    }

    onSelectionChanged(e) {
        let modules = e.value.map((v) => {
            return { Module: v };
        });
        this.setState({
            license: {
                ...this.state.license,
                ModulesBought: modules,
            },
        });
    }

    getDeployStatus() {
        store.dispatch(getDeployStatus(this.state.licenseId)).then((result) => {
            this.setState({
                deployStatusString: result.deployStatusString,
                deployStatus: result.deployStatus,
            });
        });
    }

    onLicenseChanged(e) {
        if (this.state.license) {
            this.setState({
                loading: true,
            });
            store.dispatch(updateLicense(this.state.license)).then((result) => {
                this.setState({
                    loading: false,
                });
            });
        }
    }

    onStoreNameChanged(e) {
        if (this.state.license) {
            this.setState({
                loading: true,
            });
            store.dispatch(updateLicense(this.state.license)).then((result) => {
                this.setState({
                    loading: false,
                });
            });
        }
    }
}

export default License;
