import React, { Component } from "react";
import {
    addInstanceToSyncTargets,
    getAllLicensesByResellerId,
    getSyncTargets,
    removeInstanceFromSyncTargets,
} from "../../../../actions/licensesActions";
import store from "../../../../store";
import { SelectBox, TreeList } from "devextreme-react";
import { Column, Editing, Pager, Paging, Texts } from "devextreme-react/tree-list";
import { connect } from "react-redux";

class Sync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseId: this.props.licenseId,
            syncTargets: [],
            licenses: [],
            targetLicenseToAdd: null,
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        store.dispatch(getSyncTargets(this.state.licenseId)).then((response) => {
            this.setState({ syncTargets: response });
        });
        store.dispatch(getAllLicensesByResellerId(this.props.identity.user.resellerId)).then((json) => {
            this.setState({ licenses: json });
        });
    }

    handleCreate(e) {
        store
            .dispatch(addInstanceToSyncTargets(this.state.licenseId, this.state.targetLicenseToAdd.LicenseId))
            .then(() => {
                this.setState({ targetLicenseToAdd: null });
                this.loadData();
            });
    }

    handleRemove(e) {
        let targetLicense = this.state.licenses.filter((license) => e.data.url.includes(license.deployFrontendUrl))[0];
        store.dispatch(
            removeInstanceFromSyncTargets(this.state.licenseId, targetLicense.LicenseId).then(() => {
                this.loadData();
            })
        );
    }

    render() {
        console.log(this.state.syncTargets);
        return (
            <div className="licenses">
                <React.Fragment>
                    <h1>Syncziele dieser Instanz</h1>
                    <TreeList
                        dataSource={this.state.syncTargets}
                        keyExpr={"id"}
                        noDataText="Keine Syncziele vorhanden"
                        showBorders={true}
                        showRowLines={true}
                        columnAutoWidth={true}
                        rowAlternationEnabled={true}
                        onRowInserted={this.handleCreate}
                        onRowRemoved={this.handleRemove}
                    >
                        <Column dataField="name" caption={"Name"} />
                        <Column
                            dataField="url"
                            caption={"Url"}
                            editCellRender={() => (
                                <SelectBox
                                    items={this.state.licenses}
                                    valueExpr="LicenseId"
                                    displayExpr="deployFrontendUrl"
                                    onSelectionChanged={(e) => {
                                        this.setState({ targetLicenseToAdd: e.selectedItem });
                                    }}
                                />
                            )}
                        />
                        <Paging defaultPageSize={15} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 50, 100]} showInfo={true} />
                        <Editing allowDeleting={true} allowAdding={true} useIcons={true}></Editing>
                    </TreeList>
                </React.Fragment>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Sync);
